import { IGuidTracking } from './Model/IGuidTracking';
import { IScenarioTracking } from './Model/IScenarioTracking';
import { Injectable } from '@angular/core';
import { LoggerService } from './logger.service';
import { IInteraction } from '@amc-technology/davinci-api';
import { ICadDisplayConfig } from './Model/ICadDisplayConfig';
@Injectable()
export class StorageService {
  public scenarioToCADMap: {
    [scenarioId: string]: any
  };

  public displayCadList: {
    [scenarioId: string]: {
      [key: string]: string
    }
  };

  public currentScenarioId: string;
  public displayCadData: boolean;
  public interactionEvents: Object;

  public scenarioTracking: {
    [scenarioId: string]: IScenarioTracking;
  };

  public guidTracking: {
    [generatedGuid: string]: IGuidTracking;
  };

  constructor(private loggerService: LoggerService) {
    this.interactionEvents = {};
    this.scenarioToCADMap = {};
    this.displayCadList = {};
    this.displayCadData = false;
  }

  public getGuidTracking(): {[generatedGuid: string]: IGuidTracking} {
    return this.guidTracking;
  }

  public setGuidTracking(guidTracking: {[generatedGuid: string]: IGuidTracking}): void {
    try {
      this.guidTracking = guidTracking;
      this.storeToLocalStorage()
    } catch (error) {
      this.loggerService.logger.logError('SAP - Storage : ERROR : Set GUID Tracking : Error Information : ' + JSON.stringify(error));
    }
  }

  public getScenarioTracking(): {[scenarioId: string]: IScenarioTracking} {
    return this.scenarioTracking;
  }

  public setScenarioTracking(scenarioTracking: {[scenarioId: string]: IScenarioTracking}): void {
    try {
      this.scenarioTracking = scenarioTracking;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('SAP - Storage : ERROR : Set Scenario Tracking : Error Information : ' + JSON.stringify(error));
    }
  }

  public getInteractionEvent(scenarioId: string): Object {
    return this.interactionEvents[scenarioId];
  }

  public setInteractionEvent(scenarioId: string) {
    try {
      this.interactionEvents[scenarioId] = true;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('SAP - Storage : ERROR : Set Interaction for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public removeInteractionEvent(scenarioId: string) {
    try {
      if (this.interactionEvents) {
        delete this.interactionEvents[scenarioId];
      }
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('SAP - Storage : ERROR : Remove Interaction Event for Scenario ID : '
      + scenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  protected storeToLocalStorage() {
    try {
      const prevScenarioRecord = localStorage.getItem('scenario');
      this.loggerService.logger.logDebug('SAP - Storage : Storing to Local Storage. Scenario before update : ' + prevScenarioRecord);
      const scenarioRecord = JSON.stringify({
        interactionEvents: this.interactionEvents,
        scenarioToCADMap: this.scenarioToCADMap,
        displayCadList: this.displayCadList,
        currentScenarioId: this.currentScenarioId

      });
      const scenarioTracking = JSON.stringify(this.scenarioTracking);
      const guidTracking = JSON.stringify(this.guidTracking);
      localStorage.setItem('scenario', scenarioRecord);
      localStorage.setItem('scenarioTracking', scenarioTracking);
      localStorage.setItem('guidTracking', guidTracking);
      this.loggerService.logger.logDebug('SAP - Storage : Storing to Local Storage. Scenario after update : ' + scenarioRecord);
    } catch (error) {
      this.loggerService.logger.logError('SAP - Storage : ERROR : Storing to Local Storage. Error Information : '
      + JSON.stringify(error));
    }
  }

  public syncWithLocalStorage() {
    try {
      const scenarioRecord = localStorage.getItem('scenario');
      const scenarioTracking = localStorage.getItem('scenarioTracking');
      const guidTracking = localStorage.getItem('guidTracking');
      this.loggerService.logger.logDebug('SAP - Storage : Syncing with Local Storage. Scenario information : '
      + scenarioRecord);
      const scenarioRecordJSON = JSON.parse(scenarioRecord);
      const scenarioTrackingJSON = JSON.parse(scenarioTracking);
      const guidTrackingJSON = JSON.parse(guidTracking);
      if (scenarioRecordJSON) {
        this.interactionEvents = scenarioRecordJSON.interactionEvents;
        this.scenarioToCADMap = scenarioRecordJSON.scenarioToCADMap;
        this.displayCadList = scenarioRecordJSON.displayCadList;
        this.currentScenarioId = scenarioRecordJSON.currentScenarioId;
      }
      if (scenarioTrackingJSON) {
        this.scenarioTracking = scenarioTrackingJSON;
      }
      if (guidTrackingJSON) {
        this.guidTracking = guidTrackingJSON;
      }
    } catch (error) {
      this.loggerService.logger.logError('SAP - Storage : ERROR : Syncing with Local Storage. Error Information : '
      + JSON.stringify(error));
    }
  }

  public clearScenarioCadMap(scenarioId: string) {
    delete this.scenarioToCADMap[scenarioId];
    this.storeToLocalStorage();
  }

  public setCurrentScenarioId(currentScenarioId: string) {
    try {
      this.currentScenarioId = currentScenarioId;
      this.storeToLocalStorage();
    } catch (error) {
      this.loggerService.logger.logError('SAP C4C - Storage : ERROR : Set Current Scenario ID for Scenario ID : '
        + currentScenarioId + '. Error Information : ' + JSON.stringify(error));
    }
  }

  public updateCadFields(interaction: IInteraction, cadActivityMap: Object, cadDisplayMap: ICadDisplayConfig) {
    try {

      if (!this.scenarioToCADMap) {
        this.scenarioToCADMap = {};
        }
      if (interaction.details && interaction.details.fields && !this.scenarioToCADMap[this.currentScenarioId]) {
        this.scenarioToCADMap[interaction.scenarioId] = interaction.details.fields;
        this.storeToLocalStorage();
      }
      if (interaction.details && cadDisplayMap.DisplayCad ) {
        for (const key in cadDisplayMap.CadFields) {
          if (interaction.details.fields[key]) {
            if (!this.displayCadList[interaction.scenarioId]) {
              this.displayCadList[interaction.scenarioId] = {};
            }
            this.displayCadList[interaction.scenarioId][cadDisplayMap.CadFields[key]] = interaction.details.fields[key].Value;
            this.storeToLocalStorage();
        }
      }
    }
    } catch (error) {
      this.loggerService.logger.logError('SAP C4C - Storage : ERROR : Updating CAD Fields for Scenario ID : '
      + interaction.scenarioId + ', Interaction Details : ' + JSON.stringify(interaction) + ', CAD Activity Map : '
      + JSON.stringify(cadActivityMap) + '. Error information : ' + JSON.stringify(error));
    }
 }
}

