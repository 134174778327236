import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { SapComponent } from './sap.component';
import { HomeSapComponent } from './home/home-sap.component';
import { LoggerService } from './logger.service';
import { StorageService } from './storage.service';
import { ConfigurationService } from './configuration.service';
import { CadDisplayComponent } from './cad-display/cad-display.component';
@NgModule({
  declarations: [SapComponent, HomeSapComponent, CadDisplayComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    HttpClientModule,
    FormsModule,
    RouterModule.forRoot([
      { path: '', component: HomeSapComponent, pathMatch: 'full' }
    ])
  ],
  providers: [
    ConfigurationService,
    {
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigurationService,
        loggerService: LoggerService
      ) => async () => {
        await configService.loadConfigurationData();
        await loggerService.intialize();
      },
      deps: [ConfigurationService, LoggerService],
      multi: true
    },
    LoggerService,
    StorageService
  ],
  bootstrap: [SapComponent]
})
export class AppModule { }
